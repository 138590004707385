import Tab from "./tab";
import TabPanel from "./tab-panel";
import { attachPropertiesToComponent } from "../../utils/attach-properties-to-component";

export type TabName = string | number;

export type Tab = {
  title?: string;
  content?: React.ReactNode;
  name: TabName;
};

export default attachPropertiesToComponent(Tab, {
  TabPanel,
});
