import React, { ReactElement, useCallback, useMemo } from "react";

import styles from "./tabs.module.css";
import classNames from "classnames";
import { Tab, TabName } from ".";

type TabsProps = {
  tabs: Tab[];
  children: (props: {
    isAnimation: boolean;
    activeName: TabName;
  }) => ReactElement[];
  isAnimation?: boolean;
};

export default function Tabs({ tabs, ...props }: TabsProps) {
  const [activeIndex, setActiveIndex] = React.useState<number>(0);
  const activeName = useMemo(() => {
    return tabs[activeIndex].name;
  }, [tabs, activeIndex]);

  const renderTabPanels = useCallback(() => {
    return props
      .children({
        isAnimation: props.isAnimation || false,
        activeName,
      })
      .map((child, index) => {
        return (
          <div
            className={styles.tabPanel_wrapper}
            key={`TabPanels__wrapper_${index}`}
          >
            {child}
          </div>
        );
      });
  }, [props, activeName]);

  return (
    <div className={styles.tabs}>
      <div className={styles.tabs__head}>
        {tabs.map((tab, index) => (
          <label
            key={`${tab.name}_tab_${index}`}
            className={classNames(styles.tabs__item, {
              [styles.tabs__item_active]: index === activeIndex,
            })}
            onClick={() => {
              setActiveIndex(index);
            }}
            aria-label={tab.title}
            aria-current={index === activeIndex ? "true" : 'false'}
          >
            {tab.content || tab.title}
          </label>
        ))}
      </div>

      <div className={styles.tabs_children}>
        {props.isAnimation ? (
          <div
            className={styles.tabs_content}
            style={{
              transform: `translate3d(${-activeIndex * 1380}px, 0px, 0px)`,
              transition: "transform 700ms",
            }}
          >
            {renderTabPanels()}
          </div>
        ) : (
          renderTabPanels()
        )}
      </div>
    </div>
  );
}
