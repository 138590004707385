import React, { useMemo } from "react";
import { TabName } from ".";
import styles from "./tabs.module.css";

type TabPanelProps = {
  value: TabName;
  activeName: TabName;
  isAnimation: boolean;
  children: React.ReactNode;
};

export default function TabPanel({
  value,
  children,
  activeName,
  isAnimation,
}: TabPanelProps) {
  const isActive = useMemo(() => {
    return activeName === value;
  }, [activeName, value]);

  return (
    <article
      className={styles.tabPanel_item}
      style={!isAnimation ? { display: isActive ? "block" : "none" } : {}}
    >
      {children}
    </article>
  );
}
